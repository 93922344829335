<template>
  <div class="head">
    <div style="width: 100vw">
      <!-- <img src="../image/head/head.jpg" /> -->
    </div>

    <div class="button">
      <div class="button1" @click="goHome">
        <a href="#">
          <img src="../image/head/head_home.png" />
        </a>
      </div>
      <div class="button2" @click="goBack">
        <a href="#">
          <img src="../image/head/head_back.png" />
        </a>
      </div>
      <!-- <div>
        <span
          @click="logout()"
          style="font-size: 1vw; color: #fff; margin-left: 1vw; cursor: pointer"
          >退出</span
        >
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Head",
  props: {
    isUrl: {
      type: String,
      default: "",
    },
  },
  methods: {
    goHome: function () {
      this.$router.push("home");
    },
    goBack: function () {
      // // window.history.go(-1)
      // if (this.isUrl) {
      //   this.$emit("click", "");
      // } else {
      //   this.$router.back(-1);
      // }
      this.$router.back();
    },
    logout() {
      this.$confirm("退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.removeItem("userInfo");
          this.$router.replace("/");
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}
.head {
  height: 10vh;
  background: url("../image/head/head.jpg") no-repeat center;
  background-size: 100% 100%;
  z-index: 999999;
  display: flex;
  width: 100vw;
}
.button {
  width: 15%;

  display: flex;
  // height: 71%;
  align-items: center;
}
.button1,
.button2 {
  padding: 10px;
  width: 70px;
  height: 70px;
}
.button1:hover,
.button2:hover {
  /*transform:scale(1.1);*/
  /*-webkit-transform:scale(1.1);*/
  /*-moz-transform:scale(1.1);*/
  /*-ms-transform:scale(1.1);*/
  /*-o-transform:scale(1.1);*/

  /*-webkit-box-shadow: 0 58px 36px -56px black;*/
  /*-moz-box-shadow: 0 58px 36px -56px black;*/
  /*box-shadow: 0 58px 36px -56px black;*/

  /*transition:all .6s ease-in-out;*/
  /*-webkit-transition:all .6s ease-in-out;*/
  /*-moz-transition:all .6s ease-in-out;*/
  /*-ms-transition:all .6s ease-in-out;*/
  /*-o-transition:all .6s ease-in-out;*/
}
.button1 {
  border-right: 1px solid #f4f4f4;
}
</style>
