<template>
  <div class="foot">
    <p>甘肃省文化和旅游厅</p>
  </div>
</template>

<script>
export default {
  name: "Foot",
  props: {
    isUrl: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.foot {
  height: 5vh;
  background: #0e2333;
  color: #fff;
  font-size: 1.1vw;
  letter-spacing: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
